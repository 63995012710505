//
// Type Styles
// =============================================================================

//
// Defaults
// --------

i,
em,
.italic {
	font-style: italic;
}

b,
strong,
.bold {
	font-weight: $font-weight-bold;
}

small,
.small {
	font-size: 75%;
	vertical-align: text-bottom;
}


//
// Paragraphs
// --------

p:not(:last-child) {
	margin-bottom: $base-spacing;
}


//
// Links
// -----

a {
	background-color: transparent;
	color: $orange;
	cursor: pointer;
	text-decoration: none;
	transition: $transition-duration;

	&:hover {
		text-decoration: underline;
	}

}


//
// Lists
// --------

ul,
ol {
	padding-left: $base-spacing;
	margin-bottom: $base-spacing;

	ul, ol {
		margin-bottom: 0;
	}

}

ul {

	ul {
		list-style-type: square;
	}

	ol {
		list-style-type: lower-roman;
	}

}

ol {

	ol {
		list-style-type: lower-roman;
	}

	ul {
		list-style-type: square;
	}

}


//
// Blockquotes
// ---------

blockquote {
	color: $blue;
	font-size: 24px;
	line-height: 1.4;
	font-style: italic;
	padding: 0;
	margin-bottom: $base-spacing;

	p {

		&:last-of-type {
			margin-bottom: 0;
		}

	}

	cite,
	footer {
		display: block;
		font-size: rem(14px);
		color: $black;
		font-style: italic;
	}

	cite:before,
	footer:before {
		content: "- ";
	}

}

//
// Pre, Code, and User Input
// -------------------------

code,
pre,
kbd,
samp {
	font-family: $font-family-mono;
	font-size: rem(14px);
}

pre {
	padding: $base-spacing/2;
}

code {
	vertical-align: middle;
	white-space: nowrap;
}

code,
pre {
	background-color: white;
	border: 1px solid $black;
	border-radius: 5px;
	margin-bottom: $base-spacing;
}

code {
	padding: 2px 3px;
}

pre code {
	background-color: transparent;
	border: none;
	padding: 0;
	vertical-align: inherit;
	white-space: pre;
}

//
// Addresses
// ---------

address {
	font-style: normal;
}
