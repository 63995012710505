/* ===========================
   @box
   =========================== */

.box {
	background-color: white;
	padding: 30px;
	border-radius: 5px;
	box-shadow: 0px 2px 20px rgba(0,0,0,0.20);

	@include breakpoint($bp-small) {
		padding: 40px;
	}

	+ .box {
		margin-top: $base-spacing;
	}

	&__title {
		font-size: 18px;
		line-height: 1.2;
		margin: 0 0 10px 0;

		@include breakpoint($bp-small) {
			font-size: 20px;
		}

	}

	&__text {

		@include breakpoint($bp-small) {
			font-size: 16px;
		}

	}

}
