//
// Headings
// =============================================================================

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	color: $blue;
	margin: 0 0 $base-spacing 0;
	padding: 0;
	font-weight: normal;
	font-family: $font-secondary;
	line-height: 1.1;
	-webkit-font-smoothing: antialiased;
}

h1, .h1 {
	font-size: 34px;

	@include breakpoint($bp-extra-small) {
		font-size: 38px;
	}

	@include breakpoint($bp-small) {
		font-size: 48px;
	}

	@include breakpoint($bp-large) {
		font-size: 56px;
		line-height: 1.2;
	}

}

h2, .h2,
h3, .h3,
h3, .h4  {
	font-size: 24px;
	margin-bottom: 20px;

	@include breakpoint($bp-small) {
		font-size: 32px;
	}

}
