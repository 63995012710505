//
// Globals
// =============================================================================

$max-width:             1200px;

$bp-extra-small: 		480px;			// Extra small screen / phone
$bp-small: 				576px;			// Small screen / phone
$bp-medium: 			768px;			// Medium screen / tablet
$bp-large: 				1000px;			// Large screen / desktop

// Font sizes
$base-font-size-sm:		15px;
$base-font-size:		17px;
$base-line-height:      1.8;
$base-spacing:          30px;

// Font families
$font-primary:          "Montserrat", "Arial", sans-serif;
$font-secondary:        "Intro", "Arial", sans-serif;
$font-family-mono:      "Consolas", monospace;

// Font families
$font-weight-light:		300;
$font-weight-regular:	600;
$font-weight-bold:		600;

// Transition duration
$transition-duration:   250ms;
$tempo:   				$transition-duration;

// Colors
$blue:         			#005BEA;
$blue-light:       		#00C6FB;
$blue-tint:       		#F2FCFF;
$orange:       			#FF8700;
$orange-light:       	#FFAE53;
$black:                 #000000;
$red:					#EC5050;
$green:					#6BCF3A;
