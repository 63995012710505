/* ===========================
   @section
   =========================== */

.section {

	$root: &;

	padding-top: 60px;
	padding-bottom: 60px;
	position: relative;

	@include breakpoint($bp-medium) {
		padding-top: 100px;
		padding-bottom: 100px;
	}

	&__container {
		padding-left: 20px;
		padding-right: 20px;
		margin: 0 auto;
		max-width: 1020px;
	}

	&__title {

		&--centered {
			text-align: center;
		}

	}

	&__text {

		&:not(:last-child) {
			margin-bottom: $base-spacing;
		}

		&--centered {
			text-align: center;
		}

	}

	&--white {
		background-color: white;
	}

	&--color {
		background: $blue;
		background: -moz-linear-gradient(45deg, $blue 0%, $blue-light 100%);
		background: -webkit-linear-gradient(45deg, $blue 0%, $blue-light 100%);
		background: linear-gradient(45deg, $blue 0%, $blue-light 100%);

		#{$root}__title,
		#{$root}__text {
			color: white;
			text-shadow: 0px 1px 2px rgba(0,0,0,0.3);
		}

	}

	&--arrow-top {

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: 1;
			width: 0;
			height: 0;
			border-left: 15px solid transparent;
			border-right: 15px solid transparent;
			border-top: 15px solid white;

			@include breakpoint($bp-small) {
				border-left: 25px solid transparent;
				border-right: 25px solid transparent;
				border-top: 25px solid white;
			}

		}

	}

	&--arrow-bottom {

		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: 1;
			width: 0;
			height: 0;
			border-left: 15px solid transparent;
			border-right: 15px solid transparent;
			border-bottom: 15px solid white;

			@include breakpoint($bp-small) {
				border-left: 25px solid transparent;
				border-right: 25px solid transparent;
				border-bottom: 25px solid white;
			}

		}

	}

}
