/* ===========================
   @header
   =========================== */

.header {
	position: absolute;
	z-index: 2;
	top: $base-spacing;
	left: 0;
	right: 0;
	width: 100%;

	.container {
		position: relative;

		@include breakpoint($bp-large) {
			display: flex;
			flex-direction: row;
			align-items: center;
		}

	}

	&__logo {
		width: 240px;
		margin: 0 auto $base-spacing auto;

		@include breakpoint($bp-small) {
			width: 300px;
		}

		@include breakpoint($bp-large) {
			margin: 0;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}

	}

	&__navigation {
		margin-left: auto;

		ul {
			margin: 0;
			padding: 0;
			display: flex;
			flex-direction: column;

			@include breakpoint($bp-extra-small) {
				flex-direction: row;
				justify-content: center;
			}

			li {
				list-style: none;
				margin: 0 0 10px 0;
				padding: 0;
				display: block;

				@include breakpoint($bp-extra-small) {
					display: inline-block;
					margin: 0 5px;
				}

				@include breakpoint($bp-small) {
					margin: 0 0 0 20px;
				}

				a {
					color: white;
					display: block;
					font-weight: $font-weight-bold;
					font-size: 11px;
					line-height: 1.0;
					text-align: center;
					border-radius: 48px;
					padding: 12px 20px 12px 20px;
					border: 2px solid white;

					@include breakpoint($bp-small) {
						font-size: 14px;
					}

					&:hover {
						color: $blue;
						text-decoration: none;
						background-color: white;
					}

				}

				&.current-menu-item,
				&.active {

					> a {
						color: $blue;
					}

				}

			}

		}

	}

}
