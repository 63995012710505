/* ===========================
   @base
   =========================== */

*,
::after,
::before {
	box-sizing: inherit;
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	text-decoration: inherit;
	vertical-align: inherit;
}

* {
	cursor: inherit;
	margin: 0;
	padding: 0;
}

//
// Custom fonts
// ----

@import url('https://fonts.googleapis.com/css?family=Montserrat:300,600');

@font-face {
    font-family: 'Intro';
    src: url('../fonts/intro-webfont.eot');
    src: url('../fonts/intro-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/intro-webfont.woff2') format('woff2'),
         url('../fonts/intro-webfont.woff') format('woff'),
         url('../fonts/intro-webfont.ttf') format('truetype'),
         url('../fonts/intro-webfont.svg#intro_regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

//
// Base styles
// ----

body {
	font-family: $font-primary;
	font-weight: $font-weight-light;
	color: $black;
	font-size: $base-font-size-sm;
	line-height: $base-line-height;
	box-sizing: border-box;
	cursor: default;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

	@include breakpoint($bp-small) {
		font-size: $base-font-size;
	}

}

.container {
	padding-left: 18px;
	padding-right: 18px;
	margin: 0 auto;
	max-width: $max-width;
}

//
// Misc
// ----

br {

	&.extra-small {
		display: block;

		@include breakpoint( 380px ) {
			display: none;
		}

	}

}

// Horizontal rule
hr {
	border: 0;
	border-bottom: 1px solid $black;
	display: block;
	height: 1px;
	margin: 0 0 $base-spacing 0;
}

// Figures
figure {
	margin: 0;
	padding: 0;
}

img {
	max-width: 100%;
	height: auto;
}

figcaption {
	color: $black;
	font-style: italic;
	text-align: center;
}

// Selection

::-moz-selection {
	background-color: lighten($blue, 40%);
}

::selection {
	background-color: lighten($blue, 40%);
}

// Responsive embeds

.entry-content-asset,
.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	margin-bottom: 20px;
}

.entry-content-asset,
.embed-container {

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

}

// Screen Reader Content

.screenreader {
	border: 0 none;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;

	&:active,
	&:focus {
		position: static;
		width: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		clip: auto;
	}

}
