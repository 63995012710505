/* ===========================
   @jumbotron
   =========================== */

.jumbotron {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 300px 0 80px 0;
	background-color: $blue;
	background-image: url('../images/bg-jumbotron@2x.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	@include breakpoint($bp-extra-small) {
		padding: 240px 0 80px 0;
	}

	@include breakpoint($bp-large) {
		padding: 200px 0 120px 0;
	}

	&__content {
		text-align: center;
		padding: 0 $base-spacing;
		text-shadow: 0px 1px 4px rgba(0,0,0,0.2);
	}

	&__title {
		color: white;
		max-width: 780px;
		margin: 0 auto $base-spacing auto;
	}

	&__button {
		box-shadow: 0px 2px 10px rgba(0,0,0,0.15);

		&:hover {
			box-shadow: 0px 2px 20px rgba(0,0,0,0.30);
		}

	}

}
