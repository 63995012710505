//
// Buttons
// =============================================================================

.button {
	font-family: inherit;
	font-weight: $font-weight-bold;
	font-size: inherit;
	border: none;
	text-align: center;
	text-decoration: none;
	appearance: none;
	border-radius: 5px;
	line-height: 1.0;
	color: white;
	cursor: pointer;
	display: inline-block;
	padding: 18px 30px;
	border-radius: 53px;
	text-shadow: 0px 1px 2px rgba(0,0,0,0.2);
	background: $orange;
	background: -moz-linear-gradient(45deg, $orange 0%, $orange-light 100%);
	background: -webkit-linear-gradient(45deg, $orange 0%, $orange-light 100%);
	background: linear-gradient(45deg, $orange 0%, $orange-light 100%);
	transition: $transition-duration;
	position: relative;

	&:hover,
	&:focus {
		color: white;
		text-decoration: none;
	}

	&:focus,
	&:active {
		outline: none;
	}

}
