//
// Table styles
// =============================================================================

.table {
	position: relative;
	margin-bottom: $base-spacing;

	table {
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;
		empty-cells: show;
		background-color: white;
		border-bottom: 1px solid $blue-tint;
	}

	th,
	td {
		font-family: inherit;
		line-height: inherit;
		overflow: visible;
		padding: 20px;
		font-size: 14px;
		line-height: 1.4;
		text-align: center;
		vertical-align: middle;

		&.label {
			text-align: left;
		}

	}

	tr {

		&:nth-child(2n) {
			background-color: $blue-tint;
		}

	}

	th {
		color: white;
		font-size: 12px;
		line-height: 1.0;
		font-family: $font-secondary;
		font-weight: normal;
		background-color: $blue-light;
	}

	i {
		width: 20px;
		height: 20px;
		display: inline-block;
		background-size: 20px 20px;

		&.icon-check {
			background-image: url('../images/icon-check.svg');
		}

		&.icon-cross {
			background-image: url('../images/icon-cross.svg');
		}

	}

	&__shadow {
		position: absolute;
		z-index: 10;
		top: 0;
		right: 0;
		bottom: 0;
		width: 8px;
		height: 100%;
		z-index: 10;
		background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.15) 100%);
		background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.15) 100%);
		background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.15) 100%);

		@include breakpoint($bp-small) {
			display: none;
		}

	}

	//
	// Responsive
	// ----------

	&--responsive {

		.table__inner {
			overflow: auto;
			width: 100%;
			max-width: 100%;
			position: relative;

			&::-webkit-scrollbar {
				height: 14px;
				width: 14px;
				-webkit-appearance: none;
			}

			&::-webkit-scrollbar-thumb {
				background-color: rgba(50, 50, 50, .2);
				border: 3px solid white;
				border-radius: 8px;
			}

			table {
				margin-bottom: 0;
			}

		}

	}

}
